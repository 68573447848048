/**
 * 0. Icons
 * Font Awesome by Dave Gandy | http://fontawesome.io
 * ----------------------------------------------------------------------------
 */
@font-face {
  font-family: 'fontawesome';
  src: url("../fonts/fontawesome.ttf?zg5dgs") format("truetype"), url("../fonts/fontawesome.woff?zg5dgs") format("woff"), url("../fonts/fontawesome.svg?zg5dgs#fontawesome") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"],
[class*=" icon-"] {
  font-family: 'fontawesome' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
  content: "\e900";
}

.icon-bloglovin:before {
  content: "\e901";
}

.icon-patreon:before {
  content: "\e902";
}

.icon-map-marker:before {
  content: "\f041";
}

.icon-phone:before {
  content: "\f095";
}

.icon-twitter:before {
  content: "\f099";
}

.icon-facebook:before {
  content: "\f09a";
}

.icon-github:before {
  content: "\f09b";
}

.icon-feed:before {
  content: "\f09e";
}

.icon-chain:before {
  content: "\f0c1";
}

.icon-google:before {
  content: "\f0d5";
}

.icon-envelope:before {
  content: "\f0e0";
}

.icon-linkedin:before {
  content: "\f0e1";
}

.icon-mobile:before {
  content: "\f10b";
}

.icon-xing:before {
  content: "\f168";
}

.icon-youtube:before {
  content: "\f16a";
}

.icon-dropbox:before {
  content: "\f16b";
}

.icon-stack-overflow:before {
  content: "\f16c";
}

.icon-instagram:before {
  content: "\f16d";
}

.icon-flickr:before {
  content: "\f16e";
}

.icon-tumblr:before {
  content: "\f173";
}

.icon-dribbble:before {
  content: "\f17d";
}

.icon-behance:before {
  content: "\f1b4";
}

.icon-soundcloud:before {
  content: "\f1be";
}

.icon-codepen:before {
  content: "\f1cb";
}

.icon-pinterest:before {
  content: "\f231";
}

.icon-medium:before {
  content: "\f23a";
}

.icon-500px:before {
  content: "\f26e";
}

.icon-vimeo:before {
  content: "\f27d";
}

.icon-mixcloud:before {
  content: "\f289";
}
