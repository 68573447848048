/**
 * 7. Content
 * 7.1 Posts and Pages
 * ----------------------------------------------------------------------------
 */
.post {
  margin-bottom: 60px;
}

.post-header {
  margin-bottom: 30px;
}

.post-title {
  font-size: 28px;
  margin-bottom: 10px;
  a {
    border-color: transparent;
    color: #fff;
  }
}

.tag-template .post-title a,
.author-template .post-title a {
  color: #111;
}

.post-title a:hover,
.tag-template .post-title a:hover,
.author-template .post-title a:hover {
  border-color: $color-accent;
}

.post-tags {
  font: bold 10px/1.5 $font-primary;
  letter-spacing: 1px;
  margin-bottom: 15px;
  text-transform: uppercase;
  a {
    background-color: $color-accent;
    border: 2px solid transparent;
    color: #111;
    display: inline-block;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 1px 3px;
    &:hover {
      background-color: transparent;
      border-color: #fff;
      color: #fff;
    }
  }
}

.tag-template .post-tags a:hover,
.author-template .post-tags a:hover {
  background-color: transparent;
  border-color: #111;
  color: #111;
}

.post-meta {
  color: #ccc;
  font: 12px/1.5 $font-primary;
  letter-spacing: 1px;
  margin-bottom: 30px;
  text-transform: uppercase;
  a {
    border-color: transparent;
    color: $color-accent;
    &:hover {
      border-color: #fff;
      color: #fff;
    }
  }
}

.post-meta .reading-time {
  bottom: 40px;
  position: absolute;
  right: 30px;
}

.tag-template .post-meta a,
.author-template .post-meta a {
  color: #666;
  &:hover {
    border-color: $color-accent;
    color: #111;
  }
}

.author-template .post-content,
.tag-template .post-content {
  font-size: 16px;
}

.share-post {
  font: 12px/1.5 $font-primary;
  color: #ccc;
  letter-spacing: 1px;
  margin-bottom: 60px;
  margin-top: 30px;
  text-transform: uppercase;
}

.share-post .square {
  font-size: 14px;
  height: 36px;
  line-height: 2.57143;
  margin-left: 5px;
  width: 36px;
}

.author-list {
  border: 3px solid #111;
  margin-bottom: 60px;
  padding: 15px 30px;
  position: relative;
}

.author-list-title {
  background: $color-accent;
  color: #111;
  font-family: $font-primary;
  font-size: 12px;
  font-weight: bold;
  left: 50%;
  letter-spacing: 1px;
  line-height: 1.2;
  padding: 5px 10px;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.author-box {
  min-height: 75px;
  padding: 30px 0;
  position: relative;
}

.author-box + .author-box {
  border-top: 1px solid #eee;
}

.author-box.no-avatar {
  min-height: 0;
}

.author-box .author-avatar {
  border-radius: 50%;
  height: 75px;
  left: 0;
  position: absolute;
  top: 30px;
  width: 75px;
}

.author-box .author-title,
.author-box .author-description,
.author-box .author-links {
  margin-left: 105px;
}

.author-box.no-avatar .author-title,
.author-box.no-avatar .author-description,
.author-box.no-avatar .author-links {
  margin-left: 0;
}

.author-box .author-title {
  font-size: 14px;
  letter-spacing: 1px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.author-box .author-description {
  font-size: 14px;
  margin-bottom: 15px;
}

.author-links,
.post-count {
  font: 12px/1.75 $font-primary;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.author-links span {
  margin-right: 10px;
  white-space: nowrap;
}

.author-links span:last-child {
  margin-right: 0;
}

.author-box .author-links {
  color: #111;
  font-size: 10px;
  margin-bottom: 0;
}

.subscribe-box {
  background-color: #eee;
  font-size: 14px;
  margin-bottom: 60px;
  padding: 30px;
}

.subscribe-box-title {
  font-size: 16px;
  letter-spacing: 1px;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.subscribe-box p {
  margin-bottom: 15px;
}

.post-grid {
  margin-left: -30px;
}

.author-template .post-grid,
.tag-template .post-grid {
  margin-left: 0;
}

.grid-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
  margin-bottom: 30px;
  padding-left: 30px;
}

.grid-item,
.home-template .grid-item:nth-child(3n+1) {
  height: 450px;
  width: 50%;
}

.grid-item:nth-child(3n+1),
.home-template .grid-item:nth-child(3n+3) {
  height: 600px;
  width: 100%;
}

.author-template .grid-item,
.tag-template .grid-item {
  padding-left: 60px;
  padding-right: 60px;
  width: 50%;
}

.author-template .post-grid .grid-item,
.tag-template .post-grid .grid-item {
  height: auto;
}

.author-template .grid-item:nth-child(2n+1),
.tag-template .grid-item:nth-child(2n+1) {
  clear: left;
}

.post-inside {
  background-color: #111;
  height: 100%;
  position: relative;
  width: 100%;
}

.post-inside .post-header {
  color: #fff;
  padding: 60px;
  position: relative;
}

.post-inside .read-more {
  bottom: 60px;
  left: 60px;
  position: absolute;
}

.grid-item:nth-child(3n+1) .post-inside .post-title,
.home-template .grid-item:nth-child(3n+3) .post-title {
  font-size: 40px;
  max-width: 80%;
}

.home-template .grid-item:nth-child(3n+1) .post-title {
  font-size: 28px;
  max-width: none;
}

.cover {
  background-color: #111;
  color: #fff;
  display: table;
  height: 100vh;
  margin-bottom: 60px;
  position: relative;
  width: 100%;
}

.cover-tag {
  color: #111;
  display: table;
  height: 300px;
  margin-bottom: 60px;
  position: relative;
  width: 100%;
}

.cover-tag .page-title {
  color: #111;
}

.cover.hero {
  height: 600px;
  margin-bottom: 30px;
}

.cover.page-header {
  height: 360px;
}

.cover-bg,
.post-bg {
  -webkit-animation: fadeIn 1s ease-in-out;
  animation: fadeIn 1s ease-in-out;
  background-position: center;
  background-size: cover;
  bottom: 0;
  left: 0;
  opacity: 0.4;
  position: absolute;
  right: 0;
  top: 0;
}

.cover-content {
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

.hero .cover-content {
  text-align: left;
}

.cover .inner {
  padding-bottom: 30px;
  padding-top: 60px;
}

.cover.hero .inner {
  padding-left: 60px;
  padding-right: 60px;
}

.cover.post-header .inner {
  padding-bottom: 90px;
  padding-top: 120px;
}

.cover .scroll-down {
  bottom: 30px;
  left: 50%;
  margin-left: -20px;
  position: absolute;
}

.cover .scroll-down:focus {
  outline: none;
}

.hero-wrap {
  border-left: 7px solid $color-accent;
  padding-left: 45px;
}

.hero-title {
  -webkit-animation-delay: .2s;
  animation-delay: .2s;
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  color: #fff;
  font-size: 48px;
  text-transform: uppercase;
}

.hero-text {
  -webkit-animation-delay: .4s;
  animation-delay: .4s;
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  font-size: 18px;
  font-style: italic;
}

.hero .subscribe {
  -webkit-animation-delay: .6s;
  animation-delay: .6s;
  -webkit-animation-duration: .8s;
  animation-duration: .8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  margin-top: 45px;
}

.page-title,
.post-template .post-title,
.page-template .post-title {
  color: #fff;
  font-size: 40px;
}

.post-count,
.page-header .author-links {
  color: #ccc;
  margin-bottom: 15px;
}

.post-count {
  margin-bottom: 15px;
}

.page-description {
  font-size: 18px;
  font-style: italic;
}

.page-header .author-avatar {
  border: 3px solid #fff;
  border-radius: 50%;
  height: 134px;
  width: 134px;
}

.page-header .author-location {
  margin-right: 10px;
}

.page-header .author-links a {
  border-color: transparent;
  color: $color-accent;
}

.page-header .author-links a:hover {
  border-color: #fff;
  color: #fff;
}

/* Featured Carousel */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus, .slick-slide:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before, .slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-arrow {
  background-color: $color-accent;
  border-color: transparent;
  color: #111;
  margin-top: -20px;
  position: absolute;
  top: 50%;
  z-index: 1;
}

.slick-arrow:hover {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}

.slick-next {
  right: 60px;
}

.slick-prev {
  left: 60px;
}

.featured-posts {
  margin-bottom: 30px;
  position: relative;
}

.slider-loading {
  background-color: #fff;
  background-image: url(../images/ajax-loader.gif);
  background-position: center center;
  background-repeat: no-repeat;
  height: 600px;
}

.featured-slider {
  background-color: #111;
  display: none;
}

.featured-counter {
  bottom: 60px;
  color: #fff;
  font: 12px/1.5 $font-primary;
  letter-spacing: 1px;
  position: absolute;
  right: 60px;
  z-index: 1;
}

.featured-slider .slick-slide {
  height: 600px;
  position: relative;
}

.slide-bg {
  background-position: center;
  background-size: cover;
  bottom: 0;
  left: 0;
  opacity: 0.4;
  position: absolute;
  right: 0;
  top: 0;
}

.slide-content {
  color: #fff;
  display: table;
  height: 100%;
  position: relative;
  width: 100%;
}

.slide-vertical {
  display: table-cell;
  height: 100%;
  vertical-align: middle;
  width: 100%;
}

.slide-vertical .inner {
  padding: 30px 150px;
}

.slide-vertical .post-title {
  font-size: 48px;
}

/**
 * 7.2 Comments
 * ----------------------------------------------------------------------------
 */
.comments-area {
  border: 2px solid #eee;
  box-sizing: border-box;
  margin-bottom: 3.33333em;
  padding: 35px 30px 45px;
}

.comments-title {
  cursor: pointer;
  font-size: 16px;
  letter-spacing: 1px;
  position: relative;
  text-transform: uppercase;
}

.comments-title .icon-plus-custom {
  background: $color-accent;
  left: 0;
  margin-top: -15px;
  position: absolute;
  top: 50%;
}

/**
 * 7.3 Paging Navigation
 * ----------------------------------------------------------------------------
 */
.pagination {
  color: #111;
  font: 12px/1.5 $font-primary;
  letter-spacing: 1px;
  margin-bottom: 90px;
  margin-top: 30px;
  padding: 11px 60px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

.newer-posts,
.older-posts {
  position: absolute;
  top: 0;
}

.newer-posts {
  left: 0;
}

.older-posts {
  right: 0;
}

/**
 * 7.4 Post Navigation
 * ----------------------------------------------------------------------------
 */
.post-navigation {
  background-color: #111;
  font-family: $font-primary;
  overflow: hidden;
}

.nav-previous,
.nav-next {
  border: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
  display: block;
  overflow: hidden;
  padding-bottom: 300px;
  position: relative;
  width: 50%;
}

.nav-previous {
  float: right;
  text-align: right;
}

.nav-next {
  float: left;
  text-align: left;
}

.nav-previous:first-child,
.nav-next:last-child {
  text-align: center;
  width: 100%;
}

.nav-next + .nav-previous {
  border-left: 1px solid #252525;
}

.nav-bg {
  background-position: center;
  background-size: cover;
  bottom: 0;
  left: 0;
  opacity: 0.4;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transition: 1s;
  -moz-transition: 1s;
  transition: 1s;
}

.nav-previous:hover,
.nav-next:hover {
  color: #fff;
}

.nav-previous:hover .nav-bg,
.nav-next:hover .nav-bg {
  -webkit-transform: scale(1.05, 1.05);
  -moz-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
}

.nav-inside {
  bottom: 30px;
  left: 30px;
  overflow: hidden;
  position: absolute;
  right: 30px;
  top: 90px;
}

.nav-before {
  color: $color-accent;
  display: block;
  font-size: 10px;
  letter-spacing: 1px;
  margin-bottom: 15px;
  position: relative;
  text-transform: uppercase;
}

.nav-title {
  color: #fff;
  display: block;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 5px;
}

.nav-date {
  color: #ccc;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}
