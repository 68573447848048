/**
 * 3. Structure
 * ----------------------------------------------------------------------------
 */
.site {
  overflow: hidden;
  position: relative;
}

.inner-wide {
  margin: 0 auto;
  max-width: 1600px;
  padding-left: 30px;
  padding-right: 30px;
}

.inner {
  margin: 0 auto;
  max-width: 740px;
  padding-left: 30px;
  padding-right: 30px;
}